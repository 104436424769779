import BuilderSDK from "@builder.io/sdk";
import React from "react";

import { ServerSidePropsContext, StaticPropsContext } from "@/types/page";

export type BuilderConfig<P> = {
    component: React.ComponentType;
    config: BuilderSDK.Component;
    asyncProps?: (
        context: ServerSidePropsContext | StaticPropsContext,
        props: P
    ) => Promise<Record<string, unknown>>;
};

export const BuilderModel = {
    PAGE: "page",
    BANNER: "banner",
    MESSAGE: "message",
    NAV_LINK: "navigation-link",
    WEB_FOOTER: "web-footer",
    WEB_REGIONAL_FOOTER: "web-regional-footer",
    GLOBAL_FOOTER_STRUCTURE: "global-footer-structure",
};
export type BuilderModel = typeof BuilderModel[keyof typeof BuilderModel];

export type BuilderMapperProps<T> = {
    data: T;
};
export type AsyncPropsMapper<T> = (props: BuilderMapperProps<T>) => Record<string, unknown>;
